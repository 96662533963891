'use client'

import Card from '@/components/Card'
import { DashboardCompany } from '@/types'
import formatDate from '@/utils/formatDate'
import formatDateRelative from '@/utils/formatDateRelative'
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline'
import {
  Cog6ToothIcon,
  DocumentIcon,
  XMarkIcon,
} from '@heroicons/react/24/solid'
import { TextInput } from '@mantine/core'
import { modals } from '@mantine/modals'
import * as Tooltip from '@radix-ui/react-tooltip'
import { createColumnHelper, SortingState } from '@tanstack/react-table'
import Link from 'next/link'
import { useRef, useState } from 'react'
import CompanyContent from './CompanyContent'
import Headers from './Headers'
import PriorityTable from './PriorityTable'

export default function DashboardTable({
  topPriorityCompanies,
  highPriorityCompanies,
  mediumPriorityCompanies,
  lowPriorityCompanies,
  total,
  nowIsoString,
}: {
  topPriorityCompanies: DashboardCompany[]
  highPriorityCompanies: DashboardCompany[]
  mediumPriorityCompanies: DashboardCompany[]
  lowPriorityCompanies: DashboardCompany[]
  total: number
  nowIsoString: string
}) {
  const columnHelper = createColumnHelper<DashboardCompany>()
  const [sorting, setSorting] = useState<SortingState>([])
  const [globalFilter, setGlobalFilter] = useState('')
  const filterInputRef = useRef<HTMLInputElement>(null)

  const defaultColumns = [
    columnHelper.accessor('name', {
      id: 'name',
      cell: (info) => (
        <div className="text-xs 2xl:text-base">
          <CompanyContent
            company={info.row.original}
            nowIsoString={nowIsoString}
          />
        </div>
      ),
      header: () => (
        <span className="text-xs 2xl:text-base font-semibold uppercase">
          Company
        </span>
      ),
      sortingFn: 'alphanumeric',
      meta: {
        classNameProp: 'col-span-2',
      },
    }),
    columnHelper.accessor('ownershipExpiresOn', {
      id: 'ownershipExpiresOn',
      cell: (info) => (
        <span className="text-xs 2xl:text-base flex justify-left capitalize">
          {formatDate(info.getValue())}
        </span>
      ),
      header: () => (
        <span className="text-xs 2xl:text-base font-semibold uppercase">
          Expires On
        </span>
      ),
      sortingFn: 'datetime',
    }),
    columnHelper.accessor('employeeCountLatest', {
      id: 'employeeCountLatest',
      cell: (info) => (
        <span className="text-xs 2xl:text-base flex justify-left">
          {Intl.NumberFormat('en-US').format(info.getValue())}
        </span>
      ),
      header: () => (
        <span className="text-xs 2xl:text-base font-semibold uppercase">
          Headcount
        </span>
      ),
      sortingFn: 'alphanumeric',
    }),
    columnHelper.accessor('totalInteractions', {
      id: 'totalInteractions',
      cell: (info) => (
        <span className="text-xs 2xl:text-base flex justify-left">
          {info.getValue()}
        </span>
      ),
      header: () => (
        <span className="text-xs 2xl:text-base font-semibold uppercase">
          # Interactions
        </span>
      ),
      sortingFn: 'alphanumeric',
    }),
    columnHelper.accessor('mostRecentActivityDate', {
      id: 'mostRecentActivityDate',
      cell: (info) => (
        <div className="text-xs 2xl:text-base justify-left">
          <div className="grid grid-cols-[5rem_1.5rem] 2xl:grid-cols-[8rem_1.5rem] gap-1">
            <div className="flex flex-col gap-1">
              <span
                title={info.row.original.mostRecentActivityType || 'unknown'}
                className="overflow-hidden text-ellipsis whitespace-nowrap capitalize"
              >
                {info.row.original.mostRecentActivityType || 'unknown'}
              </span>
              <span
                title={formatDate(info.getValue()) || 'unknown'}
                className="text-xs"
              >
                {formatDateRelative(info.getValue())}
              </span>
            </div>
            <div className="flex flex-col justify-center">
              <Tooltip.Provider delayDuration={400}>
                <Tooltip.Root>
                  <Tooltip.Trigger asChild>
                    <button
                      className="flex justify-center hover:cursor-pointer rounded p-1 hover:bg-gray-200"
                      onClick={() =>
                        modals.openContextModal({
                          modal: 'ViewLatestNoteModal',
                          title: 'Latest Note',
                          innerProps: {
                            companySalesforceId: info.row.original.salesforceId,
                          },
                          size: '40vw',
                        })
                      }
                    >
                      <DocumentIcon className="h-3 w-3 2xl:h-4 2xl:w-4" />
                    </button>
                  </Tooltip.Trigger>
                  <Tooltip.Portal>
                    <Tooltip.Content side="bottom" sideOffset={3}>
                      <div className="bg-navbar text-white rounded-sm py-1 px-4 text-sm">
                        <p>View Latest Note</p>
                      </div>
                      <Tooltip.Arrow className="fill-battery-triangle" />
                    </Tooltip.Content>
                  </Tooltip.Portal>
                </Tooltip.Root>
              </Tooltip.Provider>
            </div>
          </div>
        </div>
      ),
      header: () => (
        <span className="text-xs 2xl:text-base font-semibold uppercase">
          Last Interaction
        </span>
      ),
      sortingFn: 'datetime',
    }),
    columnHelper.accessor('coverageStatus', {
      id: 'coverageStatus',
      cell: ({
        row: {
          original: { coverageStatus, addOnCandidateName, addOnCandidateId },
        },
      }) => {
        if (/Covered - Add-on candidate/i.test(coverageStatus)) {
          return (
            <div className="text-xs 2xl:text-base flex flex-col gap-1">
              <div>
                <p>Candidate To:</p>
                <Link
                  href={`/company/${addOnCandidateId}`}
                  className="underline underline-offset-auto text-battery-triangle"
                >
                  {addOnCandidateName}
                </Link>
              </div>
            </div>
          )
        }

        return <p className="text-xs 2xl:text-base">{coverageStatus}</p>
      },
      header: () => (
        <span className="text-xs 2xl:text-base font-semibold uppercase">
          Coverage
        </span>
      ),
      sortingFn: (
        {
          original: {
            coverageStatus: statusA,
            addOnCandidateName: candidateNameA,
          },
        },
        {
          original: {
            coverageStatus: statusB,
            addOnCandidateName: candidateNameB,
          },
        }
      ) =>
        `${statusA}-${candidateNameA}`.localeCompare(
          `${statusB}-${candidateNameB}`
        ),
    }),
    columnHelper.accessor('ownerName', {
      id: 'ownerName',
      cell: (info) => (
        <div className="text-xs 2xl:text-base flex flex-col gap-1">
          <p>{info.row.original.ownerName}</p>
          {info.row.original.ownerName !==
            info.row.original.effectiveOwnerName && (
            <div className="2xl:text-xs 2xl:flex 2xl:gap-1">
              <p>Effective:</p>
              <p>{info.row.original.effectiveOwnerName}</p>
            </div>
          )}
        </div>
      ),
      header: () => (
        <span className="text-xs 2xl:text-base font-semibold uppercase">
          Owner
        </span>
      ),
    }),
  ]

  return (
    <Card>
      <div className="flex items-center justify-between mb-4">
        <div className="flex items-baseline gap-2">
          <h1 className="text-xl text-cyan-800 font-semibold uppercase">
            prioritized companies
          </h1>
          <span className="text-sm">
            {Intl.NumberFormat('en-US').format(total)} total
          </span>
        </div>

        <div className="flex justify-center gap-2">
          <TextInput
            size="xs"
            ref={filterInputRef}
            type="text"
            placeholder="Filter Dashboard"
            value={globalFilter}
            onChange={(e) => setGlobalFilter(e.target.value)}
            leftSection={<MagnifyingGlassIcon className="h-4 w-4" />}
            leftSectionPointerEvents="none"
            rightSection={
              globalFilter.length > 0 && (
                <XMarkIcon
                  className="h-4 w-4 hover:cursor-pointer"
                  onClick={(e) => {
                    setGlobalFilter('')
                    if (filterInputRef.current) {
                      filterInputRef.current.focus()
                    }
                  }}
                />
              )
            }
          />
          <button
            onClick={() =>
              modals.openContextModal({
                modal: 'DashboardSettingsModal',
                title: 'Dashboard Settings',
                size: '20vw',
                innerProps: null,
              })
            }
          >
            <Cog6ToothIcon className="h-6 w-6" />
          </button>
        </div>
      </div>

      <div className="overflow-x-scroll lg:overflow-visible">
        <div className="w-[50rem] lg:w-full">
          <Headers
            defaultColumns={defaultColumns}
            sorting={sorting}
            setSorting={setSorting}
          />
          <PriorityTable
            priority="top"
            nowIsoString={nowIsoString}
            defaultColumns={defaultColumns}
            sorting={sorting}
            globalFilter={globalFilter}
            data={topPriorityCompanies}
          />
          <PriorityTable
            priority="high"
            nowIsoString={nowIsoString}
            defaultColumns={defaultColumns}
            sorting={sorting}
            globalFilter={globalFilter}
            data={highPriorityCompanies}
          />
          <PriorityTable
            priority="medium"
            nowIsoString={nowIsoString}
            defaultColumns={defaultColumns}
            sorting={sorting}
            globalFilter={globalFilter}
            data={mediumPriorityCompanies}
          />
          <PriorityTable
            priority="low"
            nowIsoString={nowIsoString}
            defaultColumns={defaultColumns}
            sorting={sorting}
            globalFilter={globalFilter}
            data={lowPriorityCompanies}
          />
        </div>
      </div>
    </Card>
  )
}
