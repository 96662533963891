'use client'

import Card from '@/components/Card'
import { DashboardCompany } from '@/types'
import formatDate from '@/utils/formatDate'
import { PencilIcon } from '@heroicons/react/24/solid'
import { modals } from '@mantine/modals'
import * as ScrollArea from '@radix-ui/react-scroll-area'
import Link from 'next/link'
import { useState } from 'react'
import { Virtuoso } from 'react-virtuoso'

export default function ExpiringCompaniesSection({
  companies,
  title,
  dateAnnotation,
}: {
  companies: DashboardCompany[]
  title: string
  dateAnnotation: string
}) {
  const [scrollParent, setScrollParent] = useState<HTMLElement | null>()

  const openModal = (company: DashboardCompany) =>
    modals.openContextModal({
      modal: 'EditDashboardCompanyModal',
      title: `Modifying ${company.name}`,
      innerProps: {
        company,
      },
    })

  return (
    <Card className="flex flex-col gap-4 p-0 py-4 pl-4">
      <Link
        href={`/${title.toLowerCase().split(' ').join('-')}`}
        className="flex flex-col gap-4"
      >
        <div className="flex items-baseline gap-2">
          <h1 className="text-xl text-cyan-800 font-semibold uppercase">
            {title}
          </h1>
          <span className="text-sm">
            {Intl.NumberFormat('en-US').format(companies.length)} total
          </span>
        </div>
      </Link>
      <ScrollArea.Root className="h-[370px] overflow-hidden">
        <ScrollArea.Viewport className="w-full h-full" ref={setScrollParent}>
          <div className="pr-4">
            <Virtuoso
              data={companies}
              itemContent={(_idx, company) => (
                <div
                  key={company.id}
                  className="flex items-center justify-between border-b border-neutral-200 py-2 group/row"
                >
                  <div className="flex gap-2">
                    <Link href={`/company/${company.id}`}>
                      <div className="flex items-center max-w-[9rem] lg:max-w-[12rem] gap-1">
                        {/* eslint-disable-next-line @next/next/no-img-element */}
                        <img
                          src={company.logoUrl || 'https://placehold.co/40x40'}
                          alt={`${company.name} logo`}
                          width={40}
                          height={40}
                          className="h-[40px] object-contain"
                        />
                        <p className="">{company.name}</p>
                      </div>
                    </Link>
                    {company.salesforceId && (
                      <div className="hidden flex-col justify-center group-hover/row:flex group-hover/row:visible">
                        <button
                          className="hover:cursor-pointer rounded p-2 hover:bg-gray-200"
                          onClick={() => openModal(company)}
                        >
                          <PencilIcon className="h-4 w-4 text-battery-triangle" />
                        </button>
                      </div>
                    )}
                  </div>

                  <p>
                    <span className="font-semibold">{dateAnnotation}</span>:{' '}
                    {formatDate(company.ownershipExpiresOn)}
                  </p>
                </div>
              )}
              customScrollParent={scrollParent ?? undefined}
            />
          </div>
        </ScrollArea.Viewport>
        <ScrollArea.Scrollbar
          className="flex select-none touch-none p-0.5 bg-transparent transition-colors duration-[160ms] ease-out data-[orientation=vertical]:w-2.5 data-[orientation=horizontal]:flex-col data-[orientation=horizontal]:h-2.5"
          orientation="vertical"
        >
          <ScrollArea.Thumb className="flex-1 bg-scroll-thumb rounded-[10px] relative before:content-[''] before:absolute before:top-1/2 before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2 before:w-full before:h-full before:min-w-[44px] before:min-h-[44px]" />
        </ScrollArea.Scrollbar>
      </ScrollArea.Root>
    </Card>
  )
}
