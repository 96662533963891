import { DashboardCompany } from '@/types'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid'
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table'
import clsx from 'clsx'
import { Dispatch, SetStateAction } from 'react'

const Headers = ({
  defaultColumns,
  sorting,
  setSorting,
}: {
  defaultColumns: ColumnDef<DashboardCompany, any>[]
  sorting: SortingState
  setSorting: Dispatch<SetStateAction<SortingState>>
}) => {
  const table = useReactTable({
    getCoreRowModel: getCoreRowModel(),
    data: [],
    columns: defaultColumns,
    state: {
      sorting,
    },
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
  })

  return table.getHeaderGroups().map((headerGroup) => (
    <div
      key={headerGroup.id}
      className="grid grid-cols-8 gap-2 border-b border-neutral-200"
    >
      {headerGroup.headers.map((header, idx) => (
        <div
          key={header.id}
          className={clsx(header.column.columnDef.meta?.classNameProp, {
            'pl-2': idx === 0,
          })}
        >
          {header.isPlaceholder ? null : (
            <div
              onClick={header.column.getToggleSortingHandler()}
              className={clsx('flex justify-left gap-2 items-center', {
                'cursor-pointer select-none': header.column.getCanSort(),
              })}
            >
              {flexRender(header.column.columnDef.header, header.getContext())}
              {{
                asc: <ChevronUpIcon className="h-4" />,
                desc: <ChevronDownIcon className="h-4" />,
              }[header.column.getIsSorted() as string] ?? null}
            </div>
          )}
        </div>
      ))}
    </div>
  ))
}

export default Headers
