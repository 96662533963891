'use client'

import Card from '@/components/Card'
import { DashboardCompany } from '@/types'
import { QuestionMarkCircleIcon } from '@heroicons/react/24/solid'
import * as Tooltip from '@radix-ui/react-tooltip'
import {
  ArcElement,
  Chart as ChartJS,
  Tooltip as ChartTooltip,
  Legend,
} from 'chart.js'
import { Doughnut } from 'react-chartjs-2'

ChartJS.register(ArcElement, ChartTooltip, Legend)

export default function MyCompaniesChart({
  top,
  high,
  medium,
  low,
  dashboardData,
  salesforceUserId,
  teamSalesforceUserIds,
}: {
  top: number
  high: number
  medium: number
  low: number
  dashboardData: DashboardCompany[]
  salesforceUserId: string | null
  teamSalesforceUserIds?: string[]
}) {
  const dashboardStats = dashboardData.reduce<Record<string, number>>(
    (acc, company) => {
      const status = String(company.coverageStatus)
        .toLowerCase()
        .replaceAll(' ', '_')
        .replaceAll('-', '')
        .replaceAll('__', '_')
      const isOwner = [
        ...(teamSalesforceUserIds || []),
        salesforceUserId,
      ].includes(company.ownerId)
      const isEffectiveOwner = [
        ...(teamSalesforceUserIds || []),
        salesforceUserId,
      ].includes(company.effectiveOwnerId)

      /**
       * Possible Keys
       *  'covered_owner'
       *  'covered_effective_owner'
       *  'covered_addon_candidate_owner'
       *  'covered_addon_candidate_effective_owner'
       *  'open_owner'
       *  'open_effective_owner'
       *  'conflict_owner'
       *  'conflict_effective_owner'
       *  'dead_owner'
       *  'dead_effective_owner'
       *  'portfolio_active_owner'
       *  'portfolio_inactive_effective_owner'
       *  'uncategorized'
       */
      const key =
        !isOwner && !isEffectiveOwner
          ? 'uncategorized'
          : `${status}_${isOwner ? 'owner' : 'effective_owner'}`

      if (acc[key]) {
        acc[key] += 1
      } else {
        acc[key] = 1
      }

      return acc
    },
    {}
  )

  const showCovered =
    Boolean(dashboardStats['covered_owner']) ||
    Boolean(dashboardStats['covered_effective_owner'])
  const showAddOnCandidates =
    Boolean(dashboardStats['covered_addon_candidate_owner']) ||
    Boolean(dashboardStats['covered_addon_candidate_effective_owner'])
  const showOpen =
    Boolean(dashboardStats['open_owner']) ||
    Boolean(dashboardStats['open_effective_owner'])
  const showConflict =
    Boolean(dashboardStats['conflict_owner']) ||
    Boolean(dashboardStats['conflict_effective_owner'])
  const showDead =
    Boolean(dashboardStats['dead_owner']) ||
    Boolean(dashboardStats['dead_effective_owner'])
  const showPortActive =
    Boolean(dashboardStats['portfolio_active_owner']) ||
    Boolean(dashboardStats['portfolio_active_effective_owner'])
  const showPortInactive =
    Boolean(dashboardStats['portfolio_inactive_owner']) ||
    Boolean(dashboardStats['portfolio_inactive_effective_owner'])
  const showOther = Boolean(dashboardStats['uncategorized'])

  return (
    <Card className="flex flex-col gap-4">
      <h1 className="text-xl uppercase text-cyan-800 font-semibold">
        My Companies
      </h1>
      <div className="flex justify-center">
        <div className="w-[180px]">
          <Doughnut
            options={{
              cutout: '60%',
              borderColor: 'transparent',
              plugins: {
                legend: {
                  display: false,
                },
              },
            }}
            data={{
              labels: [
                'low priority',
                'medium priority',
                'high priority',
                'top priority',
              ],
              datasets: [
                {
                  data: [low, medium, high, top],
                  backgroundColor: [
                    'rgb(55, 192, 167)',
                    'rgb(251, 207, 102)',
                    'rgb(249, 115, 22)',
                    'rgb(233, 105, 112)',
                  ],
                },
              ],
            }}
          />
        </div>
      </div>

      <div className="flex gap-8 justify-center">
        <div className="flex flex-col items-center">
          <span className="text-3xl font-extrabold text-low-priority">
            {Intl.NumberFormat('en-US').format(low)}
          </span>
          <span>Low</span>
        </div>
        <div className="flex flex-col items-center">
          <span className="text-3xl font-extrabold text-medium-priority">
            {Intl.NumberFormat('en-US').format(medium)}
          </span>
          <span>Medium</span>
        </div>
        <div className="flex flex-col items-center">
          <span className="text-3xl font-extrabold text-high-priority">
            {Intl.NumberFormat('en-US').format(high)}
          </span>
          <span>High</span>
        </div>
        <div className="flex flex-col items-center">
          <span className="text-3xl font-extrabold text-top-priority">
            {Intl.NumberFormat('en-US').format(top)}
          </span>
          <span>Top</span>
        </div>
      </div>

      <div>
        <p className="text-lg text-cyan-800 font-semibold uppercase">
          {Intl.NumberFormat('en-US').format(dashboardData.length)} TOTAL
        </p>

        {showCovered && (
          <div className="flex flex-col">
            <p className="self-center text-cyan-800 font-semibold">Covered</p>
            <div className="flex justify-center gap-4">
              <div className="flex gap-1">
                <p>
                  {Intl.NumberFormat('en-US').format(
                    dashboardStats['covered_owner'] || 0
                  )}
                </p>
                <p>Owner</p>
              </div>
              <div className="flex gap-1">
                <p>
                  {Intl.NumberFormat('en-US').format(
                    dashboardStats['covered_effective_owner'] || 0
                  )}
                </p>
                <p>Effective Owner</p>
              </div>
            </div>
          </div>
        )}

        {showAddOnCandidates && (
          <div className="flex flex-col">
            <p className="self-center text-cyan-800 font-semibold">
              Add-on Candidates
            </p>
            <div className="flex justify-center gap-4">
              <div className="flex gap-1">
                <p>
                  {Intl.NumberFormat('en-US').format(
                    dashboardStats['covered_addon_candidate_owner'] || 0
                  )}
                </p>
                <p>Owner</p>
              </div>
              <div className="flex gap-1">
                <p>
                  {Intl.NumberFormat('en-US').format(
                    dashboardStats['covered_addon_candidate_effective_owner'] ||
                      0
                  )}
                </p>
                <p>Effective Owner</p>
              </div>
            </div>
          </div>
        )}

        {showOpen && (
          <div className="flex flex-col">
            <p className="self-center text-cyan-800 font-semibold">Open</p>
            <div className="flex justify-center gap-4">
              <div className="flex gap-1">
                <p>
                  {Intl.NumberFormat('en-US').format(
                    dashboardStats['open_owner'] || 0
                  )}
                </p>
                <p>Owner</p>
              </div>
              <div className="flex gap-1">
                <p>
                  {Intl.NumberFormat('en-US').format(
                    dashboardStats['open_effective_owner'] || 0
                  )}
                </p>
                <p>Effective Owner</p>
              </div>
            </div>
          </div>
        )}

        {showConflict && (
          <div className="flex flex-col">
            <p className="self-center text-cyan-800 font-semibold">Conflict</p>
            <div className="flex justify-center gap-4">
              <div className="flex gap-1">
                <p>
                  {Intl.NumberFormat('en-US').format(
                    dashboardStats['conflict_owner'] || 0
                  )}
                </p>
                <p>Owner</p>
              </div>
              <div className="flex gap-1">
                <p>
                  {Intl.NumberFormat('en-US').format(
                    dashboardStats['conflict_effective_owner'] || 0
                  )}
                </p>
                <p>Effective Owner</p>
              </div>
            </div>
          </div>
        )}

        {showDead && (
          <div className="flex flex-col">
            <p className="self-center text-cyan-800 font-semibold">Dead</p>
            <div className="flex justify-center gap-4">
              <div className="flex gap-1">
                <p>
                  {Intl.NumberFormat('en-US').format(
                    dashboardStats['dead_owner'] || 0
                  )}
                </p>
                <p>Owner</p>
              </div>
              <div className="flex gap-1">
                <p>
                  {Intl.NumberFormat('en-US').format(
                    dashboardStats['dead_effective_owner'] || 0
                  )}
                </p>
                <p>Effective Owner</p>
              </div>
            </div>
          </div>
        )}

        {showPortActive && (
          <div className="flex flex-col">
            <p className="self-center text-cyan-800 font-semibold">
              Portfolio Active
            </p>
            <div className="flex justify-center gap-4">
              <div className="flex gap-1">
                <p>
                  {Intl.NumberFormat('en-US').format(
                    dashboardStats['portfolio_active_owner'] || 0
                  )}
                </p>
                <p>Owner</p>
              </div>
              <div className="flex gap-1">
                <p>
                  {Intl.NumberFormat('en-US').format(
                    dashboardStats['portfolio_active_effective_owner'] || 0
                  )}
                </p>
                <p>Effective Owner</p>
              </div>
            </div>
          </div>
        )}

        {showPortInactive && (
          <div className="flex flex-col">
            <p className="self-center text-cyan-800 font-semibold">
              Portfolio Inactive
            </p>
            <div className="flex justify-center gap-4">
              <div className="flex gap-1">
                <p>
                  {Intl.NumberFormat('en-US').format(
                    dashboardStats['portfolio_inactive_owner'] || 0
                  )}
                </p>
                <p>Owner</p>
              </div>
              <div className="flex gap-1">
                <p>
                  {Intl.NumberFormat('en-US').format(
                    dashboardStats['portfolio_inactive_effective_owner'] || 0
                  )}
                </p>
                <p>Effective Owner</p>
              </div>
            </div>
          </div>
        )}

        {showOther && (
          <p className="flex items-center gap-1">
            {Intl.NumberFormat('en-US').format(
              dashboardStats['uncategorized'] || 0
            )}{' '}
            Other
            <Tooltip.Provider delayDuration={0}>
              <Tooltip.Root>
                <Tooltip.Trigger asChild>
                  <QuestionMarkCircleIcon className="h-4 inline-block" />
                </Tooltip.Trigger>
                <Tooltip.Portal>
                  <Tooltip.Content side="bottom" sideOffset={3}>
                    <div className="bg-navbar text-white text-sm rounded-sm py-1 px-4 w-[250px]">
                      <p>
                        Deals where you are listed as diligence lead or
                        diligence help, or were tagged in a VP or GP Meeting in
                        Salesforce.
                      </p>
                    </div>
                    <Tooltip.Arrow className="fill-battery-triangle" />
                  </Tooltip.Content>
                </Tooltip.Portal>
              </Tooltip.Root>
            </Tooltip.Provider>
          </p>
        )}
      </div>
    </Card>
  )
}
