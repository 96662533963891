import { Company, DashboardCompany } from '@/types'
import formatAddress from '@/utils/formatAddress'
import relativeExpirationInfo from '@/utils/relativeExpirationInfo'
import { ExclamationTriangleIcon, PencilIcon } from '@heroicons/react/24/solid'
import { modals } from '@mantine/modals'
import * as Tooltip from '@radix-ui/react-tooltip'
import clsx from 'clsx'
import Link from 'next/link'

export default function CompanyContent({
  company,
  nowIsoString,
}: {
  company: DashboardCompany
  nowIsoString: string
}) {
  const { isExpiredOrExpiringSoon, distanceToExpiration, isExpired } =
    relativeExpirationInfo(company.ownershipExpiresOn, nowIsoString)

  const openModal = () =>
    modals.openContextModal({
      modal: 'EditDashboardCompanyModal',
      title: `Modifying ${company.name}`,
      innerProps: {
        company,
      },
    })

  return (
    <div
      className={clsx('flex gap-2', {
        'text-red-400': isExpiredOrExpiringSoon,
      })}
    >
      <Link href={`/company/${company.id}`} className="flex gap-4">
        {/* eslint-disable-next-line @next/next/no-img-element */}
        <img
          src={company.logoUrl || 'https://placehold.co/40x40'}
          alt={`${company.name} logo`}
          width={40}
          height={40}
          className="h-[40px] object-contain"
        />
        <div className="flex flex-col justify-center">
          <span className="inline-block">
            {company.name}

            {['website error', 'domain squatted'].includes(
              company.websiteIssueType
            ) && (
              <Tooltip.Provider delayDuration={0}>
                <Tooltip.Root>
                  <Tooltip.Trigger asChild>
                    <svg
                      className="inline-block ml-1"
                      fill="currentColor"
                      version="1.1"
                      width={16}
                      height={16}
                      id="Layer_1"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <g>
                        <g>
                          <circle cx="298.364" cy="396.443" r="9.079" />
                        </g>
                      </g>
                      <g>
                        <g>
                          <circle cx="334.679" cy="432.759" r="9.079" />
                        </g>
                      </g>
                      <g>
                        <g>
                          <circle cx="177.312" cy="432.759" r="9.079" />
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            d="M310.469,229.998H201.523c-5.015,0-9.079,4.065-9.079,9.079c0,5.014,4.064,9.079,9.079,9.079h108.946
			c5.015,0,9.079-4.065,9.079-9.079C319.548,234.062,315.484,229.998,310.469,229.998z"
                          />
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            d="M310.469,181.577H201.523c-5.015,0-9.079,4.065-9.079,9.079c0,5.014,4.064,9.079,9.079,9.079h108.946
			c5.015,0,9.079-4.065,9.079-9.079C319.548,185.642,315.484,181.577,310.469,181.577z"
                          />
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            d="M255.996,48.421c-31.274,0-60.974,10.005-85.887,28.932c-3.992,3.034-4.769,8.729-1.737,12.721
			c3.032,3.992,8.728,4.771,12.721,1.737c21.73-16.508,47.63-25.233,74.903-25.233c68.417,0,124.078,55.661,124.078,124.078v130.13
			c0,5.014,4.064,9.079,9.079,9.079c5.015,0,9.079-4.065,9.079-9.079v-130.13C398.231,112.227,334.426,48.421,255.996,48.421z"
                          />
                        </g>
                      </g>
                      <g>
                        <g>
                          <path
                            d="M495.072,478.153c-11.109,0-21.697,4.075-29.885,11.235c-5.332-4.664-11.686-8.008-18.537-9.78V190.656
			C446.652,85.528,361.124,0,255.996,0S65.34,85.528,65.34,190.656v288.952c-6.849,1.772-13.203,5.117-18.537,9.78
			c-8.187-7.16-18.775-11.235-29.884-11.235c-5.015,0-9.079,4.065-9.079,9.079c0,5.014,4.064,9.079,9.079,9.079
			c8.938,0,17.319,4.401,22.417,11.775c1.696,2.451,4.486,3.915,7.468,3.915c2.982,0,5.772-1.462,7.468-3.915
			c5.099-7.373,13.479-11.775,22.416-11.775s17.319,4.401,22.417,11.775c1.696,2.451,4.486,3.915,7.468,3.915
			c2.982,0,5.772-1.462,7.468-3.915c5.099-7.373,13.479-11.775,22.417-11.775s17.319,4.401,22.417,11.775
			c1.696,2.451,4.486,3.915,7.468,3.915s5.772-1.462,7.468-3.915c5.099-7.373,13.479-11.775,22.417-11.775
			s17.319,4.401,22.417,11.775c1.696,2.451,4.486,3.915,7.468,3.915s5.772-1.462,7.468-3.915
			c5.099-7.373,13.479-11.775,22.417-11.775c8.938,0,17.319,4.401,22.417,11.775c1.696,2.451,4.486,3.915,7.468,3.915
			s5.772-1.462,7.468-3.915c5.099-7.373,13.479-11.775,22.417-11.775c8.938,0,17.319,4.401,22.418,11.775
			c1.696,2.451,4.486,3.915,7.468,3.915s5.772-1.462,7.468-3.915c5.099-7.373,13.479-11.775,22.418-11.775
			c8.938,0,17.319,4.401,22.417,11.775c1.696,2.451,4.486,3.915,7.468,3.915s5.772-1.462,7.468-3.915
			c5.099-7.373,13.479-11.775,22.417-11.775c8.938,0,17.319,4.401,22.418,11.775c1.696,2.451,4.486,3.915,7.468,3.915
			s5.772-1.462,7.468-3.915c5.099-7.373,13.479-11.775,22.418-11.775c5.015,0,9.079-4.065,9.079-9.079
			C504.16,482.218,500.087,478.153,495.072,478.153z M428.493,478.677c-8.574,1.304-16.58,5.032-23.074,10.711
			c-2.229-1.949-4.641-3.661-7.188-5.133V357.102c0-5.014-4.064-9.079-9.079-9.079s-9.079,4.065-9.079,9.079v121.286
			c-1.501-0.151-3.014-0.235-4.539-0.235c-11.109,0-21.697,4.075-29.885,11.235c-8.188-7.161-18.776-11.235-29.885-11.235
			s-21.697,4.075-29.885,11.235c-8.188-7.161-18.776-11.235-29.885-11.235c-11.109,0-21.697,4.075-29.885,11.235
			c-8.188-7.161-18.776-11.235-29.885-11.235s-21.697,4.075-29.885,11.235c-8.188-7.161-18.776-11.235-29.885-11.235
			c-1.525,0-3.038,0.083-4.539,0.235V190.656c0-27.274,8.725-53.175,25.233-74.903c3.034-3.992,2.255-9.688-1.737-12.721
			c-3.994-3.036-9.69-2.255-12.721,1.737c-18.923,24.912-28.928,54.612-28.928,85.887v293.599c-2.547,1.472-4.958,3.184-7.188,5.133
			c-6.493-5.68-14.5-9.407-23.076-10.711V190.656c0-95.116,77.382-172.498,172.498-172.498S428.493,95.54,428.493,190.656V478.677z"
                          />
                        </g>
                      </g>
                    </svg>
                  </Tooltip.Trigger>
                  <Tooltip.Portal>
                    <Tooltip.Content side="top" sideOffset={3}>
                      <div className="bg-navbar text-white text-sm rounded-sm py-1 px-4">
                        <p>Possible dead website</p>
                      </div>
                      <Tooltip.Arrow className="fill-battery-triangle" />
                    </Tooltip.Content>
                  </Tooltip.Portal>
                </Tooltip.Root>
              </Tooltip.Provider>
            )}

            {isExpiredOrExpiringSoon && (
              <Tooltip.Provider delayDuration={0}>
                <Tooltip.Root>
                  <Tooltip.Trigger asChild>
                    <ExclamationTriangleIcon className="h-4 inline-block ml-1" />
                  </Tooltip.Trigger>
                  <Tooltip.Portal>
                    <Tooltip.Content side="top" sideOffset={3}>
                      <div className="bg-red-400 text-white rounded-sm py-1 px-4 text-center">
                        {!isExpired ? (
                          <>
                            Ownership <br />
                            expires in{' '}
                            <span className="font-semibold">
                              {distanceToExpiration}
                            </span>
                          </>
                        ) : (
                          <>
                            Ownership <br />
                            expired!
                          </>
                        )}
                      </div>
                      <Tooltip.Arrow className="fill-red-400" />
                    </Tooltip.Content>
                  </Tooltip.Portal>
                </Tooltip.Root>
              </Tooltip.Provider>
            )}
          </span>
          <span>
            {formatAddress({
              city: company.city,
              country: company.country,
              state: company.state,
            } as Company)}
          </span>
        </div>
      </Link>
      {company.salesforceId && (
        <div className="hidden flex-col justify-center group-hover/row:flex group-hover/row:visible">
          <Tooltip.Provider delayDuration={400}>
            <Tooltip.Root>
              <Tooltip.Trigger asChild>
                <button
                  className="hover:cursor-pointer rounded p-2 hover:bg-gray-200"
                  onClick={openModal}
                >
                  <PencilIcon className="h-4 w-4 text-battery-triangle" />
                </button>
              </Tooltip.Trigger>
              <Tooltip.Portal>
                <Tooltip.Content side="bottom" sideOffset={3}>
                  <div className="bg-navbar text-white rounded-sm py-1 px-4 text-sm">
                    <p>Modify Company</p>
                  </div>
                  <Tooltip.Arrow className="fill-battery-triangle" />
                </Tooltip.Content>
              </Tooltip.Portal>
            </Tooltip.Root>
          </Tooltip.Provider>
        </div>
      )}
    </div>
  )
}
