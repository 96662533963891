'use client'

import { DashboardCompany } from '@/types'
import relativeExpirationInfo from '@/utils/relativeExpirationInfo'
import * as ScrollArea from '@radix-ui/react-scroll-area'
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable,
} from '@tanstack/react-table'
import clsx from 'clsx'
import { useState } from 'react'
import { Virtuoso } from 'react-virtuoso'

const getScrollHeight = (rowCount: number) => {
  if (rowCount === 0) {
    return '100%'
  } else if (rowCount <= 9) {
    return `${rowCount * 5}rem`
  } else {
    return '40rem'
  }
}

const PriorityTable = ({
  nowIsoString,
  priority,
  defaultColumns,
  sorting,
  data,
  globalFilter,
}: {
  nowIsoString: string
  priority: 'top' | 'high' | 'medium' | 'low'
  defaultColumns: ColumnDef<DashboardCompany, any>[]
  sorting: SortingState
  data: DashboardCompany[]
  globalFilter: string
}) => {
  const [scrollParent, setScrollParent] = useState<HTMLElement | null>()

  const table = useReactTable({
    getCoreRowModel: getCoreRowModel(),
    data,
    columns: defaultColumns,
    state: {
      sorting,
      globalFilter,
    },
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  })

  const rowCount = table.getRowModel().rows.length

  return (
    <div>
      <div
        className={`py-2 pl-2 text-sm uppercase font-semibold bg-neutral-100 text-${priority}-priority`}
      >
        {priority} priority
      </div>

      <ScrollArea.Root
        // Must use style as the height value is dynamic based on the row count. This means tailwind arbitrary values will not work.
        style={{
          overflow: 'hidden',
          height: getScrollHeight(rowCount),
        }}
      >
        <ScrollArea.Viewport className="w-full h-full" ref={setScrollParent}>
          {rowCount > 0 ? (
            <Virtuoso
              data={table.getRowModel().rows}
              itemContent={(_idx, row) => {
                const { isExpiredOrExpiringSoon } = relativeExpirationInfo(
                  row.original.ownershipExpiresOn,
                  nowIsoString
                )

                return (
                  <div
                    key={row.id}
                    className={clsx('grid grid-cols-8 gap-2 p-2 group/row', {
                      'border-y border-neutral-200': !isExpiredOrExpiringSoon,
                      'border border-red-400': isExpiredOrExpiringSoon,
                    })}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <div
                        key={cell.id}
                        className={
                          cell.getContext().column.columnDef.meta?.classNameProp
                        }
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </div>
                    ))}
                  </div>
                )
              }}
              customScrollParent={scrollParent ?? undefined}
            />
          ) : (
            <div className="p-2 text-center">
              No {priority} priority companies.
            </div>
          )}
        </ScrollArea.Viewport>
        <ScrollArea.Scrollbar
          className="flex select-none touch-none p-0.5 bg-transparent transition-colors duration-[160ms] ease-out data-[orientation=vertical]:w-2.5 data-[orientation=horizontal]:flex-col data-[orientation=horizontal]:h-2.5"
          orientation="vertical"
        >
          <ScrollArea.Thumb className="flex-1 bg-scroll-thumb rounded-[10px] relative before:content-[''] before:absolute before:top-1/2 before:left-1/2 before:-translate-x-1/2 before:-translate-y-1/2 before:w-full before:h-full before:min-w-[44px] before:min-h-[44px]" />
        </ScrollArea.Scrollbar>
      </ScrollArea.Root>
    </div>
  )
}

export default PriorityTable
